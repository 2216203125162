import '../styles/components/layout.scss'

import pupa from 'pupa'
import React, { useCallback } from 'react'
import Helmet from 'react-helmet'

import { GATSBY_WEBSITE_HOST } from '../../config/env'
import { useCmsContext } from '../contexts/cms'
import { useEntity } from '../hooks/cms/use-entity'
import { usePageData } from '../hooks/cms/use-page'
import { useHrefMaker } from '../hooks/use-href-maker'
import { Footer } from './footer'
import { Header } from './header'

export interface LayoutProps {
  variant?: 'primary' | 'secondary'
  page: keyof cms.CMSData['pages']
  buttonVariant?: 'primary' | 'secondary'
  pathname: string
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  variant = 'primary',
  buttonVariant = 'secondary',
  page,
  pathname,
}) => {
  const entity = useEntity()
  const { seo } = usePageData(page) ?? {}
  const { i18n } = useCmsContext()
  const makeHref = useHrefMaker()

  const renderMeta = useCallback(
    (str = '') =>
      entity ? pupa(str, { entity }, { ignoreMissing: true }) : str,
    [entity]
  )

  const imagePath = seo?.openGraph?.image?.split('/')

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <title>{renderMeta(seo?.title)}</title>
        <meta name="description" content={renderMeta(seo?.meta?.description)} />
        <meta
          name="keywords"
          content={seo?.meta?.keywords?.map(renderMeta)?.join(',')}
        />
        <meta
          property="og:url"
          content={`https://${GATSBY_WEBSITE_HOST}${makeHref(pathname)}`}
        />
        <meta property="og:type" content={renderMeta(seo?.openGraph?.type)} />
        <meta property="og:title" content={renderMeta(seo?.openGraph?.title)} />
        <meta
          property="og:description"
          content={renderMeta(seo?.openGraph?.description)}
        />
        {imagePath ? (
          <meta
            property="og:image"
            content={`/assets/svgs/${imagePath[imagePath.length - 1]}`}
          />
        ) : null}
        <link
          rel="canonical"
          href={`https://${GATSBY_WEBSITE_HOST}${makeHref(pathname)}`}
        />
      </Helmet>
      <Header variant={variant} buttonVariant={buttonVariant} />
      {children}
      <Footer />
    </>
  )
}
