import '../styles/components/header.scss'

import { Link } from 'gatsby'
import React, { memo } from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { GATSBY_WEBSITE_HOST } from '../../config/env'
import { useNavigation } from '../hooks/cms/use-navigation'
import { useHrefMaker } from '../hooks/use-href-maker'
import { joinClassNames } from '../utils/tsx'
import { Button } from './button'
import { Drawer, useDrawerHandlers } from './drawer'
import { IconButton } from './icon-button'
import { Menu } from './icons/menu'
import { LanguageSelector } from './language-selector'
import { Logo } from './logo'

export interface HeaderProps {
  variant?: 'primary' | 'secondary'
  buttonVariant?: 'primary' | 'secondary'
}

export const Header = memo<HeaderProps>(
  ({ variant = 'primary', buttonVariant = 'secondary' }) => {
    const { openDrawer, props } = useDrawerHandlers()
    const makeHref = useHrefMaker()
    const { items, supportButton } = useNavigation('header-navigation')

    return (
      <header
        className={joinClassNames(
          'header',
          `header--variant-${variant}`,
          'container'
        )}
      >
        <div className="header__logo-container">
          <Link
            to={makeHref('/')}
            title={GATSBY_WEBSITE_HOST}
            aria-label={GATSBY_WEBSITE_HOST}
          >
            <Logo />
          </Link>
        </div>

        <nav className="header__desktop-navigation">
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <Link to={makeHref(`/${item.page}`)}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="header__desktop-addons">
          <LanguageSelector />

          {supportButton && (
            <a href={supportButton.link} target={'_blank'} rel="noreferrer">
              <Button
                variant={variant === 'secondary' ? 'tertiary' : 'secondary'}
                icon={<BsArrowRight />}
              >
                {supportButton.label}
              </Button>
            </a>
          )}
        </div>

        <div className="header__mobile-menu">
          <IconButton
            onClick={openDrawer}
            className="header__mobile-menu-button"
            variant={buttonVariant}
            aria-label="Mobile menu"
          >
            <Menu />
          </IconButton>

          <Drawer {...props} />
        </div>
      </header>
    )
  }
)
